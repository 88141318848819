import React, {useEffect, useRef, useState} from 'react';
import * as styles from './css/style.module.scss';
import img1 from './img/1.jpg';
import img2 from './img/2.jpg';
import {SpTitle} from "../SpTitle";

export const Section3Service = (props) => {

    const {yPos} = props;
    const fadeIn_1Ref = useRef(null);
    const [fadeIn_1show, setFadeIn_1show] = useState(false);

    useEffect(() => {
        const pos1 = fadeIn_1Ref.current.getBoundingClientRect().top + yPos

        if (yPos > pos1 - window.innerHeight + (fadeIn_1Ref.current.getBoundingClientRect().top * 0.5)) {
            setFadeIn_1show(true)
        }

    }, [yPos])

    return <div className={styles.wrap}>

        <div className={styles.title}><SpTitle titleText={'SERVICE'} iconColor={['#FFB328','#FFCD70','#FFE6B8']} clipPathId={'sectionTitle'}/></div>
        <div className={styles.bgImg}>
            <svg width="100%" height="100%" viewBox="0 0 672 747" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M578.853 56.7729C648.022 116.537 639.257 148.778 661.39 229.643C683.523 310.507 672.616 353.433 603.678 460.76C534.741 568.087 562.488 583.219 485.313 657.372C408.139 731.526 332.032 733.046 260.213 743.702C165.469 757.673 23.0227 709.53 -77.336 587.533C-177.695 465.536 -128.906 256.957 -78.0557 206.711C-27.205 156.465 -6.08372 135.112 22.2946 101.873C50.673 68.633 107.974 37.0934 196.109 15.9714C284.244 -5.15071 315.25 4.93281 374.811 1.8837C434.372 -1.16547 509.684 -2.99114 578.853 56.7729Z" fill="url(#paint0_radial_609_6400)"/>
                <defs>
                    <radialGradient id="paint0_radial_609_6400" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(420.5 162.379) rotate(139.255) scale(509.638 542.711)">
                        <stop stopColor="#008DE3" stopOpacity="0.3"/>
                        <stop offset="0.145833" stopColor="#008DE3" stopOpacity="0.5"/>
                        <stop offset="0.541667" stopColor="#008DE3"/>
                        <stop offset="1" stopColor="#0064BF"/>
                    </radialGradient>
                </defs>
            </svg>
        </div>

        <div className={styles.list}>
            <a className={`${styles.card} fadeIn ${fadeIn_1show ? 'show' : ''}`} ref={fadeIn_1Ref} href='https://mento.jp/' target='_blank' rel="noreferrer">
                <div className={styles.cardImg}>
                    <img src={img1} alt=""/>
                </div>
                <div className={styles.cardTitle}>個人向けパーソナルコーチングサービス「mento」</div>
                <div className={styles.cardText}>キャリアや人間関係など人生に関するあらゆるテーマで、¥5,000/回から気軽にコーチングが体験できます。人生にモヤモヤを抱える20代-40代のミレニアル世代を中心に10,000人以上の方に利用いただいております。</div>
            </a>
            <a className={`${styles.card} fadeIn ${fadeIn_1show ? 'show' : ''}`} href='https://forbiz.mento.jp/' target='_blank' rel="noreferrer">
                <div className={styles.cardImg}>
                    <img src={img2} alt=""/>
                </div>
                <div className={styles.cardTitle}>法人向けビジネスコーチングサービス「mento for Business」</div>
                <div className={styles.cardText}>リーダー育成や組織開発に特化したコーチングプログラムをご提供しております。提供プロジェクト数は100件を越え、幅広い企業の皆さまにご利用いただいております。</div>
            </a>
        </div>
    </div>
}

